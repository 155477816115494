<i18n>
ru:
  averageTimeWarning: Ориентировочное время выполнения заказа
  dateLabel: День
  dayDefault: День
  deliveryASAPLabel: Как можно скорее
  hourDefault: Час
  minuteDefault: Минута
  minutesPeriodText: 'с {start} до {end}'
  noDelivery: 'Невозможно сделать заказ, номер ошибки #1dts'
  scheduledDeliveryLabel: Ко времени
  timeLabel: Время
ua:
  averageTimeWarning: Орієнтовний час виконання замовлення
  dateLabel: Дата
  dayDefault: Дата
  deliveryASAPLabel: На найближчий час
  hourDefault: Час
  minuteDefault: Хвилини
  minutesPeriodText: 'з {start} до {end}'
  noDelivery: 'Неможливо зробити замовлення, номер помилки #1dts'
  scheduledDeliveryLabel: На вказаний час
  timeLabel: Час
us:
  averageTimeWarning: Estimated order delivery time
  dateLabel: Day
  dayDefault: Day
  deliveryASAPLabel: As soon as possible
  hourDefault: Hour
  minuteDefault: Minute
  minutesPeriodText: 'from {start} to {end}'
  noDelivery: 'It is impossible to make a reservation, the error number #1dts'
  scheduledDeliveryLabel: Delivery to
  timeLabel: Time
</i18n>

<template>
  <div
    class="v-delivery-time-block v-mb-sm"
    :class="{ 'v-dark': isDarkMode }"
  >
    <div
      v-if="noDelivery"
      class="v-error-color"
      v-html="translate('deliveryTimeRathloriel.noDelivery')"
    />
    <template v-else-if="restrictions">
      <arora-option-slider
        :index-enabled="indexEnabled"
        :label="translate('deliveryTimeRathloriel.noDelivery')"
        :options="[true, false]"
        v-model:selected="rightNow"
      >
        <template #option="option: { value: boolean }">
          <div
            v-if="option.value"
            data-test-id="delivery-quicker"
            v-html="translate('deliveryTimeRathloriel.deliveryASAPLabel')"
          />
          <div
            v-else
            data-test-id="delivery-scheduled"
            v-html="translate('deliveryTimeRathloriel.scheduledDeliveryLabel')"
          />
        </template>
      </arora-option-slider>
      <div
        v-if="
          (restrictions?.ASAP && !restrictions.ASAP.AvailableNow) ||
          (restrictions?.Scheduled && restrictions.Scheduled.EnabledByTerminalSettings)
        "
      >
        <div
          v-if="restrictions?.Scheduled && restrictions.Scheduled.EnabledByTerminalSettings"
          class="v-d-flex v-flex-row v-time-selection"
          :class="{ disabled: deliveryTimeASAP }"
          :inert="deliveryTimeASAP ?? false"
        >
          <div class="v-flex-50 v-px-sm v-mb-sm">
            <arora-select
              v-if="daysOptions"
              default-option
              required
              v-form-validator="{
                Disabled: deliveryTimeASAP,
                Form: validatorForm,
                Value: day,
                Required: true,
                Validations: ['select']
              }"
              :default-option-text="translate('deliveryTimeRathloriel.dayDefault')"
              :disabled="!orderTypeSelected"
              :label="translate('deliveryTimeRathloriel.dateLabel')"
              :options="daysOptions.map((item) => item.value)"
              data-test-id="day-select"
              v-model:selected="day"
            >
              <template #index="options: { index: number }">
                <option
                  :value="daysOptions[options.index].value"
                  v-html="sanitize(daysOptions[options.index].text)"
                />
              </template>
            </arora-select>
          </div>

          <div class="v-flex-25 v-px-sm mb-3 time-select-dotted">
            <div class="v-delivery-select-wrapper">
              <arora-select
                default-option
                required
                v-form-validator="{
                  Disabled: deliveryTimeASAP,
                  Form: validatorForm,
                  Value: hour,
                  Required: true,
                  Validations: ['selzero']
                }"
                :default-option-text="translate('deliveryTimeRathloriel.hourDefault')"
                :disabled="!orderTypeSelected"
                :label="translate('deliveryTimeRathloriel.timeLabel')"
                :options="hoursOptions?.map((item) => item.value)"
                data-test-id="hour-select"
                v-model:selected="hour"
              >
                <template
                  v-if="hoursOptions"
                  #index="options: { index: number }"
                >
                  <option
                    :value="hoursOptions[options.index].value"
                    v-html="sanitize(hoursOptions[options.index].text)"
                  />
                </template>
              </arora-select>
            </div>
          </div>

          <div class="v-flex-25 v-px-sm mb-3">
            <arora-select
              default-option
              required
              v-form-validator="{
                Disabled: deliveryTimeASAP,
                Form: validatorForm,
                Value: minute,
                Required: true,
                Validations: ['selzero']
              }"
              :default-option-text="translate('deliveryTimeRathloriel.minuteDefault')"
              :disabled="!orderTypeSelected"
              :label="translate('deliveryTimeRathloriel.timeLabel')"
              :options="minutesOptions?.map((item) => item.value)"
              data-test-id="minute-select"
              v-model:selected="minute"
            >
              <template
                v-if="minutesOptions"
                #index="options: { index: number }"
              >
                <option
                  :value="minutesOptions[options.index].value"
                  v-html="sanitize(minutesOptions[options.index].text)"
                />
              </template>
            </arora-select>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type {
  LimitOrderDate,
  LimitOrderPeriod,
  Period,
  TerminalDeliveryMinute,
  TerminalMinutesSetting
} from '~types/clientStore'
import type { PossibleOptions } from '~types/common'

import { vFormValidator } from '@arora/common'

import { MinutesSettingType, OrderType } from '~api/consts'

const { sanitize, translate } = useI18nSanitized()
const { eventOn } = useEmitter()

const { terminal } = useAddressSelector()

const appConfig = useAppConfig()
const clientStore = useClientStore()
const menuStore = useMenuStore()

const { fromMillisInZone } = useDateTime()
const day = ref<number | undefined>(undefined)
const hour = ref<number | undefined>(undefined)
const minute = ref<number | undefined>(undefined)

const validatorForm = 'cart'

onMounted(() => {
  eventOn('v-time-refresh', () => {
    const time: number | undefined = clientStore.ClientState?.data?.StateOrderData?.DeliveryTime
    if (time) {
      const dt = fromMillisInZone(time * 1000, appConfig.RestaurantSettingsPreRun.GMT)

      day.value = dt.startOf('day').toMillis()
      hour.value = dt.hour * 60 * 60 * 1000
      minute.value = dt.minute * 60 * 1000
    }
  })
})

function indexEnabled(value: boolean): boolean {
  if (!restrictions.value) return false

  if (value) return (restrictions.value.ASAP?.EnabledByTerminalSettings ?? true) && ASAPEnabled.value

  return (restrictions.value.Scheduled?.EnabledByTerminalSettings ?? true) && ScheduledEnabled.value
}

const rightNow = computed<boolean>({
  get() {
    return clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? false
  },
  set(value) {
    clientStore.updateOrderData({
      deliverRightNow: value,
      refreshState: true
    })
  }
})
const ASAPEnabled = computed<boolean>(() => {
  return (
    (restrictions.value && restrictions.value.ASAP?.AvailableNow && orderTypeSelected.value) ?? false
  )
})
const ScheduledEnabled = computed<boolean>(() => {
  return (restrictions.value && orderTypeSelected.value) ?? false
})
const orderTypeSelected = computed<boolean>(() => {
  return clientStore.ClientState?.data?.OrderType !== OrderType.Default
})
const isDarkMode = computed<boolean>(() => {
  return menuStore.CurrentGroup?.UseAlternateTheme
    ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.DarkTheme
    : appConfig.VueSettingsPreRun.Theme.DefaultTheme.DarkTheme
})
const deliveryTimeASAP = computed<boolean | null>(() => {
  const asap = clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? true
  if (ASAPEnabled.value && asap) {
    return asap
  } else if (ScheduledEnabled.value && !asap) {
    return asap
  }

  return null
})
const restrictions = computed<LimitOrderPeriod | null>(() => clientStore?.TimeRestrictions.data)
const minutesSetting = computed<TerminalMinutesSetting | undefined>(() => {
  return restrictions.value?.Scheduled?.MinutesSetting
})

const daysOptions = computed<PossibleOptions[] | null>(() => {
  const daysOptions = [] as PossibleOptions[]

  if (!restrictions.value || restrictions.value.Scheduled === null) {
    return daysOptions
  }

  for (const dateKey in restrictions.value.Scheduled.Schedule.Dates) {
    const availableDate = restrictions.value.Scheduled.Schedule.Dates[dateKey]

    const availableDT = fromMillisInZone(availableDate.Date, appConfig.RestaurantSettingsPreRun.GMT)
    const optionText = availableDT.toLocaleString({ day: '2-digit', month: 'long' })

    daysOptions.push({
      text: optionText,
      value: availableDate.Date
    })
  }

  return daysOptions
})
const hoursOptions = computed<PossibleOptions[] | null>(() => {
  const hoursOptions: PossibleOptions[] = []

  const selectedDate = day.value ? getSelectedDate(restrictions.value, day.value) : null
  if (selectedDate === null) {
    return null
  }

  const minutesSettingType = minutesSetting.value?.MinutesSettingType ?? MinutesSettingType.Default
  let minutesFromHourStart = 0

  for (const period of selectedDate.RestrictedPeriods) {
    let hoursIterator = fromMillisInZone(period.Start, appConfig.RestaurantSettingsPreRun.GMT)
    const endPeriod = fromMillisInZone(period.End, appConfig.RestaurantSettingsPreRun.GMT)

    let minutesStep = clientStore.DeliveryTime.minutesStep

    if (minutesSettingType !== MinutesSettingType.Default) {
      const minutes = minutesSetting.value?.DeliveryMinutes ?? []
      if (minutes.length > 0) {
        const lastMinute: TerminalDeliveryMinute = minutes[minutes.length - 1]
        const firstMinute: TerminalDeliveryMinute = minutes[0]

        const minutesLeft = 60 - lastMinute.PeriodStart
        minutesFromHourStart =
          minutesSettingType === MinutesSettingType.Value
            ? firstMinute.PeriodStart
            : (firstMinute.PeriodEnd ?? firstMinute.PeriodStart)

        minutesStep = minutesLeft
      }
    }

    while (hoursIterator.plus({ minutes: minutesFromHourStart }) < endPeriod) {
      if (60 - hoursIterator.minute > minutesStep) {
        hoursOptions.push({
          period: period,
          text: hoursIterator.startOf('hour').toFormat('HH'),
          value: hoursIterator.startOf('hour').valueOf() - selectedDate.Date
        })
      }
      // every hour option must point to start of an hour for correct minutes generation
      hoursIterator = hoursIterator.startOf('hour').plus({ hours: 1 })
    }
  }

  return hoursOptions
})
const minutesOptions = computed<PossibleOptions[] | null>(() => {
  if (!day.value || !hour.value) {
    return null
  }

  const selectedDate = getSelectedDate(restrictions.value, day.value)
  if (!selectedDate) return null

  const hourTimestamp = hour.value

  // get selected period
  let selectedPeriod: Period | null = null

  for (const period of selectedDate.RestrictedPeriods) {
    if (selectedPeriod === null || period.Start - hourTimestamp < selectedPeriod.Start - hourTimestamp) {
      selectedPeriod = period
    }
  }

  if (selectedPeriod === null) {
    console.warn(`Can't get selected period for ${hourTimestamp}`)

    return null
  }

  let minutesSettingType = minutesSetting.value?.MinutesSettingType ?? MinutesSettingType.Default

  const minutes: TerminalDeliveryMinute[] = minutesSetting.value?.DeliveryMinutes ?? []
  if (minutes.length === 0) {
    minutesSettingType = MinutesSettingType.Default
  }

  let minutesOptions: PossibleOptions[] = []
  const periodStartHour = selectedPeriod.Start - selectedDate.Date
  const periodEndHour = selectedPeriod.End - selectedDate.Date

  const startTimestamp = Math.max(hourTimestamp, periodStartHour)
  const minutesStep = clientStore.DeliveryTime.minutesStep

  let start = fromMillisInZone(startTimestamp, appConfig.RestaurantSettingsPreRun.GMT)
  const periodEnd = fromMillisInZone(periodEndHour, appConfig.RestaurantSettingsPreRun.GMT)

  // add minutes to start from minutes that evenly divisible by minutesStep
  // it could be a problem in case if period is smaller than minutesStep * 2 (don't do it)
  const minutesStepRemainder = start.minute % minutesStep
  const minutesToAddToFit = minutesStepRemainder === 0 ? 0 : minutesStep - minutesStepRemainder
  start = start.plus({ minutes: minutesToAddToFit }).startOf('minute')

  // take end of an hour to avoid showing 00 minutes from the next hour
  const nextHour = start.plus({ hours: 1 }).startOf('hour').plus({ minutes: -1 })

  let end = nextHour
  if (periodEnd < nextHour) {
    end = periodEnd
  }

  let minutesIterator = start

  switch (minutesSettingType) {
    case MinutesSettingType.Value:
      minutesOptions = minutes
        .filter(
          (minute) => minute.PeriodStart >= minutesIterator.minute && minute.PeriodStart <= end.minute
        )
        .map((minute) => {
          const current = minutesIterator.startOf('hour').plus({ minutes: minute.PeriodStart })

          return {
            text: current.toFormat('mm'),
            value: current.valueOf() - hourTimestamp
          }
        })
      break
    case MinutesSettingType.Range:
      minutesOptions = minutes
        .filter(
          (minute) =>
            minute.PeriodStart >= minutesIterator.minute &&
            ((minute.PeriodEnd && minute.PeriodEnd < end.minute) ||
              (minute.PeriodEnd === 0 && end.minute === 59))
        )
        .map((minute) => {
          const currentStart = minutesIterator.startOf('hour').plus({ minutes: minute.PeriodStart })
          const currentEnd = minutesIterator.startOf('hour').plus({ minutes: minute.PeriodEnd })

          return {
            text: translate('deliveryTimeRathloriel.minutesPeriodText', {
              end: currentEnd.toFormat('mm'),
              start: currentStart.toFormat('mm')
            }),
            value: currentStart.valueOf() - hourTimestamp
          }
        })
      break
    default:
      while (minutesIterator <= end) {
        minutesOptions.push({
          text: minutesIterator.toFormat('mm'),
          value: minutesIterator.valueOf() - hourTimestamp
        })

        minutesIterator = minutesIterator.plus({ minutes: minutesStep })
      }
      break
  }

  return minutesOptions
})
const noDelivery = computed<boolean>(() => {
  if (!restrictions.value) return false //no terminal checked, doesn't mean that it is error

  if (
    (terminal.value?.InHallSetting?.HallTablesNumber === 0 && clientStore.inHall) ||
    (!terminal.value?.CanOrderSelfService && clientStore.selfService)
  )
    return false

  return (
    !(restrictions.value.Scheduled?.EnabledByTerminalSettings ?? false) &&
    !(restrictions.value.ASAP?.EnabledByTerminalSettings ?? false)
  )
})

function getSelectedDate(restrictions: LimitOrderPeriod | null, day: number): LimitOrderDate | null {
  let selectedDate: LimitOrderDate | null = null

  if (!restrictions || restrictions?.Scheduled === null) {
    return selectedDate
  }

  for (const dateKey in restrictions.Scheduled.Schedule.Dates) {
    const availableDate = restrictions.Scheduled.Schedule.Dates[dateKey]

    if (availableDate.Date === day) {
      selectedDate = availableDate
    }
  }

  return selectedDate
}

watch(
  () => day.value,
  (value: number | undefined) => {
    let deliveryTime = 0
    if (value) deliveryTime = (value + (hour.value ?? 0) + (minute.value ?? 0)) / 1000

    if (deliveryTime !== 0) {
      clientStore.updateOrderData({
        deliveryTime: deliveryTime,
        refreshState: true
      })

      hour.value = undefined
      minute.value = undefined
    }
  }
)

watch(
  () => hour.value,
  (value: number | undefined) => {
    let deliveryTime = 0
    if (value) deliveryTime = ((day.value ?? 0) + value + (minute.value ?? 0)) / 1000

    if (deliveryTime !== 0) {
      clientStore.updateOrderData({
        deliveryTime: deliveryTime,
        refreshState: true
      })

      minute.value = undefined
    }
  }
)

watch(
  () => minute.value,
  (value: number | undefined) => {
    let deliveryTime = 0
    if (value) deliveryTime = ((day.value ?? 0) + (hour.value ?? 0) + value) / 1000

    if (deliveryTime !== 0) {
      clientStore.updateOrderData({
        deliveryTime: deliveryTime,
        refreshState: true
      })
    }
  }
)
</script>

<style lang="scss">
@use 'assets/variables';

.v-delivery-time-block {
  .v-arora-option-slider {
    margin-bottom: 1rem;
  }

  select {
    font-size: variables.$TextSizeMain;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
  }
}

.v-time-selection {
  margin-left: -15px;
  margin-right: -15px;
}

.v-delivery-time-label {
  display: block;
}

.v-delivery-select-wrapper {
  position: relative;

  &:after {
    content: ':';
    display: block;
    position: absolute;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;
  }
}

.v-average-time-block {
  font-size: 1.15rem;
  font-weight: 600;
  background: variables.$OptionsBackground;
  padding: 8px;
  border-radius: variables.$BorderRadiusInput;
  margin-bottom: 10px;
}
</style>
